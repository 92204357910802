import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import logo from "./../img/Logo.png"
import axios from 'axios';
import Order from "../components/order/Order";





function TabPanel(props) {
    const { children, value, index, ...other } = props;
    



    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p:3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  

const Orders = (props) => {
    const [orders, setOrders] =useState([])
    const contextValue=useContext(Context)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    useEffect(()=>
      {
        axios.get(`/api/orders/my/${contextValue.school_id}`)
        .then(res=>{setOrders(res.data)})
        .catch(err=>console.log(err)) 
      },[contextValue.setOpenAlert]);

    
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    return (       
        <>
        <Helmet>
        <title>Заявки</title>
        <meta name="description" content="Управляйте своими заявками" />
        </Helmet>      
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">                           
                            
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>                                                     
                              <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"                               
                              >
                               
                                <Tab label="Активные заявки" {...a11yProps(0)} />                                
                                <Tab label="Архив заявок" {...a11yProps(1)} />
                              </Tabs>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={value}
                              onChangeIndex={handleChangeIndex}
                            >
                              <TabPanel value={value} index={0} dir={theme.direction}>
                              {/* <div className="container"> */}
                                  <ul className="orders">
                                        {orders?.filter((x)=>x.order_archive===0).map((p,index)=>
                                            {
                                                return <Order
                                                order_id={p.order_id}
                                                championat_id={p.championat_id} 
                                                championat_name={p.championat_name} 
                                                championat_start_date={p.championat_start_date}
                                                championat_type_name={p.championat_type_name} 
                                                championat_style_name={p.championat_style_name}
                                                championat_end_date={p.championat_end_date}
                                                championat_final_date_order={p.championat_final_date_order}
                                                championat_adress={p.championat_adress}
                                                school_id={p.school_id}
                                                school_name={p.school_name}
                                                img={logo} 
                                                index={index}
                                                archive={p.order_archive}
                                                />
                                            })} 
               
                                    </ul>
                                {/* </div> */}
                              </TabPanel>
                              <TabPanel value={value} index={1} dir={theme.direction}>
                              {/* <div className="container"> */}
                                  <ul className="orders">
                                        {orders?.filter((x)=>x.order_archive===1).map((p,index)=>
                                            {
                                                return <Order
                                                order_id={p.order_id}
                                                championat_id={p.championat_id} 
                                                championat_name={p.championat_name} 
                                                championat_start_date={p.championat_start_date}
                                                championat_type_name={p.championat_type_name} 
                                                championat_style_name={p.championat_style_name}
                                                championat_end_date={p.championat_end_date}
                                                championat_final_date_order={p.championat_final_date_order}
                                                championat_adress={p.championat_adress}
                                                school_id={p.school_id}
                                                school_name={p.school_name}
                                                img={logo} 
                                                index={index}
                                                archive={p.order_archive}
                                                />
                                            })} 
               
                                    </ul>
                                {/* </div> */}
                              </TabPanel>
                              
                            </SwipeableViews>
                           
                          </Box>
                          
                        </div>
                    </div>
                </div>
            </>
     );
}
 
export default Orders;