import { useEffect, useState } from "react";
import {Context} from "../context";
import {useContext } from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Back = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const TrenerPage = (props) => {
    const contextValue=useContext(Context)
    const [trener,setTrener]=useState({        
      trener_id:'',
      trener_family:'',
      trener_name:'',
      trener_otchestvo:'',
      trener_age:'',
      sud:'',
      trener:'',
      secret:''
    })

   
           

    

    const [selected_sud_categ, setSelectSudCateg] =useState(null) 
    const [selectedDate, setSelectedDate] =useState(null)
    const [sud_categ_types, setSudCategTypes] =useState([])
    const [sud_categs, setSudCategs] =useState([])
    const [sud_categ,setSudCateg]=useState({        
        trener_id:'',
        sud_categ_date_start:null,
        sud_categ_date_end:"",
        sud_categ_org:'',
        sud_categ_prikaz:'',
        sud_categ_type_id:""
      })

      function ClearSudCateg()
      {
        setSelectedDate(null);
        setSelectSudCateg(null)
        setSudCateg({...sud_categ,
            sud_categ_date_start:null,
            sud_categ_date_end:"",
            sud_categ_org:'',
            sud_categ_prikaz:'',
            sud_categ_type_id:""})
      }

      async function getSudCategs(id) {      
        //let response = await axios.get(`/api/razryads/get_sud_categ/${id}`);
        let response = await axios.get(`/api/razryads/sud_categs/${id}`);
        let sud_categs = response.data;// Don't need await here
        setSudCategs(sud_categs);
        console.log("Судейские категории: ", {sud_categs})
    };
      async function getSudCategTypes() {      
        let response = await axios.get(`/api/razryads/sud_categ_types`);
        let sud_categ_types = response.data;// Don't need await here
        setSudCategTypes(sud_categ_types);
        console.log("типы судейских категорий ", {sud_categ_types})
    };


    function getTrener()
    {          
            axios.get(`/api/treners/trener/${contextValue.select_trener}`)
            .then(res=>{
            console.log(res.data[0])
            setTrener({...trener,
                trener_id:res.data[0].trener_id,
                trener_family:res.data[0].trener_family,
                trener_otchestvo:res.data[0].trener_otchestvo,                
                trener_age:res.data[0].trener_age,
                trener_name:res.data[0].trener_name,
                sud:res.data[0].sud,
                trener:res.data[0].trener,
                secret:res.data[0].secret
            })
            setSudCateg({...sud_categ,trener_id:res.data[0].trener_id})
            getSudCategs(res.data[0].trener_id)
        })
        .catch(err =>alert(err))
        
      }   
      
      useEffect(()=>
    {
        if(contextValue.select_trener!==null && contextValue.select_trener!==undefined)
        {getTrener();
        getSudCategTypes();}
        
    },[contextValue.select_trener, contextValue.setOpenAlert])

    
    const [pass,setPass] = useState ({
        old_password:'',
        new_password:'',
        new_password_check:''
    })

    const [user_data,setUserData] = useState ({
        user_email:""
    })

    const [orgData,setOrgData] = useState ({
        school_name:"",
        school_adress:"",
        school_inn:""
    })

    useEffect(()=>{
        setUserData({...user_data,
        user_email:contextValue.user_email
        })
        // console.log(user_data)
    },[contextValue.user_email])
    
        useEffect(()=>{
            setOrgData({...orgData,
                school_name:contextValue.school.school_name,
                school_adress:contextValue.school.school_adress,
                school_inn:contextValue.school.school_inn
            })
            // console.log(orgData)
        },[contextValue.school])
        
    useEffect(()=>{
        if(sud_categ.sud_categ_date_start!==null && sud_categ.sud_categ_type_id!=="")
        {
            let date_end=new Date(sud_categ.sud_categ_date_start);
            date_end.setFullYear(date_end.getFullYear()+sud_categ_types[sud_categ_types.findIndex(x=>x.sud_categ_id===sud_categ.sud_categ_type_id)].sud_categ_time)
            setSudCateg({...sud_categ,sud_categ_date_end:date_end})
        }
    },[sud_categ.sud_categ_date_start,sud_categ.sud_categ_type_id])

    function handleSubmit(event)
    {
        can=true;
        contextValue.setMsg('');
        checkInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/treners/trener/edit/${trener.trener_id}`,trener)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Тренер изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Тренер изменен`)
              contextValue.setOpenAlert(true)
              props.setActive(false)       
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))  
        }
    }

    function AddSudCateg(event)
    {

        event.preventDefault();
        axios.post("/api/razryads/add_sud_categ/"+trener.trener_id,sud_categ)
        .then(res=>{
            if(res.data.Status==="Судейская категория добавлена")
            { 
                contextValue.setTypeMsg("success")
                contextValue.setMsg(`Судейская категория добавлена`)
                contextValue.setOpenAlert(true)  
            }
            else alert(res.data.Error);
        })
        .catch(err =>alert(err));    
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    function deleteSudCateg(id)
    {
        console.log(id)
        axios.post(`/api/razryads/delete_sud_categ/${id}/${trener.trener_id}`)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Судейская категория удалена")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Судейская категория  удалена`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))            
    }
    
    let can=true;
    function checkInput()
    {         
         if(trener.trener_family==='')
         {
            contextValue.setMsg("Введите фамилию")
            can=false
         }
         else if(trener.trener_name==='')
         {
            contextValue.setMsg("Введите имя")
            can=false
         }    
         else if(trener.trener_age==='')
         {
            contextValue.setMsg("Введите дату рождения")
            can=false
         }
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    function checkEmailInput()
    {      
        if(user_data.user_email==='')
            {
                can=false;
                contextValue.setMsg("Не введен Email") 
            }
            else
            {
             if (!validateEmail(user_data.user_email))  {
                 can=false;
                 contextValue.setMsg("Email не корректен") 
               }
            }
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    function checkPasswordInput()
    {      
            if(pass.old_password==='')
            {
                can=false;
                contextValue.setMsg("Не введен старый пароль") 
               
            }   
       
           else if(pass.new_password==='')
           {
               can=false;
               contextValue.setMsg("Не введен новый пароль") 
              
           }
           else if(pass.new_password!==pass.new_password_check)
           {
               can=false;
               contextValue.setMsg("Пароли не совпадают") 
           } 
         if(!can)
         {  
            contextValue.setTypeMsg("error")
            contextValue.setOpenAlert(true)
        }
    }
    const [openList, setOpenList] =useState([])
    function handleSubmitPassword(event)
    {
        can=true;
        contextValue.setMsg('');
        checkPasswordInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/password_change/${contextValue.user_id}`,pass)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Пароль успешно изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Пароль успешно изменен`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    function handleSubmitEmail(event)
    {
        can=true;
        contextValue.setMsg('');
        checkEmailInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/email_change/${contextValue.user_id}`,user_data)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Email успешно изменен")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Email успешно изменен`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    function checkSchoolDataInput()
    {
      
          if(orgData.school_adress==='')
            {
                can=false;
                contextValue.setMsg("Не введен адрес") 
               
            }
           
      
          if(orgData.school_name==='')
         {
             can=false;
             contextValue.setMsg("Не введено называние организации") 
            
         }
      
     
     if(!can)
     {
      contextValue.setTypeMsg("error")
      contextValue.setOpenAlert(true)
      }
 }
    function handleSubmitSchoolData(event)
    {
        can=true;
        contextValue.setMsg('');
        checkSchoolDataInput();
        if(can)
        {
        event.preventDefault();
        axios.post(`/api/users/school_data_change/${contextValue.school_id}`,orgData)
        .then(res=>{
            if(res.data.Status!==undefined)
            {if(res.data.Status==="Информация успешно изменена")
            {
              contextValue.setSchool(
                {
                    school_id:contextValue.school_id,
                    school_name:orgData.school_name,
                    school_inn:orgData.school_inn,
                    school_adress:orgData.school_adress
                }
                )  
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Информация успешно изменена`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);}
            else 
            {
                contextValue.setTypeMsg("error")
                contextValue.setMsg(res.data.Error)
                contextValue.setOpenAlert(true)
            }
        })
        .catch(err =>alert(err))  
        }
    }
    const [defaultInputValue, setDefaultInputValue] =useState("")
    useEffect(()=>{
        console.log(orgData.school_adress)
        if(orgData.school_adress!=="")
       { 
        let address = `${JSON.parse(orgData.school_adress)?.data?.country!==null?JSON.parse(orgData.school_adress)?.data.country:""}${JSON.parse(orgData.school_adress)?.data?.region_with_type!==null?`, ${JSON.parse(orgData.school_adress)?.data.region_with_type}`:""}${JSON.parse(orgData.school_adress).data?.city!==null?`, ${JSON.parse(orgData.school_adress).data.city}`:""}${JSON.parse(orgData.school_adress)?.data?.street_with_type!==null?`, ${JSON.parse(orgData.school_adress)?.data?.street_with_type}`:""}${JSON.parse(orgData.school_adress)?.data?.house_type!==null?`, ${JSON.parse(orgData.school_adress)?.data?.house_type}`:""}${JSON.parse(orgData.school_adress)?.data?.house!==null?`,${JSON.parse(orgData.school_adress)?.data?.house}`:""}`
        orgData.school_adress!==""&&setDefaultInputValue(address)
        }
    },[orgData.school_adress])
    const [options, setOptions] = useState([]);
    const handleInputChange = async (event, value) => {
        setDefaultInputValue(value)
        setOpenList(false)
        if (value) {
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + `0bcf160322fba45f1c4c583a62aafa04057c0629`
            },
            body: JSON.stringify({query: value})
        }
          try {
            const response = await fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,options).then(x => x.json());
            if(response.suggestions.length>0) setOptions(response.suggestions.map(item=>item));
          } catch (error) {
            console.error(error);
          }
        } else {
          setOptions([]);
        }
        setOpenList(true)
      }
    return (       
        <Back sx={{width:{xs:"400",md:"900"}}}>        
            
        <Grid container spacing={2}  direction={{xs:"column-reverse",md:"row"}}>
         
        <Grid item xs={12} md={Boolean(trener.sud)?6:12}>
         <form onSubmit="return false">   
            
            <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            > 
                            {contextValue.trener_id===contextValue.select_trener&&
    <Accordion sx={{my:1}}>
                        <AccordionSummary
                        expandIcon={<AddOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <Typography>Изменить email</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <TextField
                                    required
                                    fullWidth
                                    sx={{my:1}}
                                    type="email"
                                    label="email"
                                    value={user_data.user_email}
                                    onChange={e=>setUserData({...user_data, user_email:e.target.value})}
                                />
                                <Button                
                            variant="contained"
                            key="editTrener"
                            sx={{my:1}}
                            fullWidth
                            onClick={handleSubmitEmail}
                        >Изменить email </Button>
                                </AccordionDetails>
                                </Accordion>}      
                         {contextValue.trener_id===contextValue.select_trener&&
    <Accordion sx={{my:1}}>
                        <AccordionSummary
                        expandIcon={<AddOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <Typography>Сменить пароль</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <TextField
                    required
                    fullWidth
                    sx={{my:1}}
                    type="password"
                    label="Старый пароль"
                    onChange={e=>setPass({...pass, old_password:e.target.value})}
                />
                                                    <TextField
                    required
                    fullWidth
                    sx={{my:1}}
                    type="password"
                    label="Новый пароль"
                    onChange={e=>setPass({...pass, new_password:e.target.value})}
                />
                <TextField
                    fullWidth
                    sx={{my:1}}
                    required
                    type="password"
                    label="Повторите новый пароль"
                    onChange={e=>setPass({...pass, new_password_check:e.target.value})}
                />
                <Button                
                            variant="contained"
                            key="editTrener"
                            sx={{my:1}}
                            fullWidth
                            onClick={handleSubmitPassword}
                        >Изменить пароль </Button>
                                </AccordionDetails>
                                </Accordion>}     
                        {contextValue.userType===0&&contextValue.select_trener===null&&<Accordion sx={{my:1}}>
                        <AccordionSummary
                        expandIcon={<AddOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Typography>Редактирование данных ведомста</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={orgData.school_name} label="Наименование организации" variant="outlined" name="family" onChange={e=>setOrgData({...orgData, school_name:e.target.value})}/>
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={orgData.school_inn} label="ИНН организации" variant="outlined" name="family" onChange={e=>setOrgData({...orgData, school_inn:e.target.value})}/>
                        <Autocomplete
                        autoHighlight={true}
                            disableClearable
                            freeSolo
                            fullWidth sx={{my:1}} 
                            open={openList}
                            options={options}
                            inputValue={defaultInputValue }
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Адрес" variant="outlined" name="championat_adress"/>)}
                            getOptionLabel={(option) =>
                                {if(option?.data!==undefined)
                                   return `${option?.data?.country!==null?option?.data.country:""}${option?.data?.region_with_type!==null?`, ${option?.data.region_with_type}`:""}${option?.data?.city!==null?`, ${option?.data.city}`:""}${option?.data?.street_with_type!==null?`, ${option?.data?.street_with_type}`:""}${option?.data?.house_type!==null?`, ${option?.data?.house_type}`:""}${option?.data?.house!==null?`,${option?.data?.house}`:""}`
                                    else return ""}}
                            onChange={(event, value)=>
                                {
                                    setOrgData({...orgData,school_adress:JSON.stringify(value)?.replaceAll("\\\"","")})}
                                }
                            filterOptions={(options, { inputValue }) =>
                                options.length>0 && options?.filter((option) => {
                                const inputWords = inputValue.toLowerCase().replace(/[.,%]/g, '').split(" ");
                                const optionWords = JSON.stringify(option.data).toLowerCase().split(" ");
                                // console.log(inputWords)
                                return inputWords.every((word) =>
                                optionWords.some((optionWord) => optionWord.includes(word))
                                );
                            })
                            }
                            />
                            <Button                
                            variant="contained"
                            sx={{my:1}}
                            fullWidth
                            onClick={handleSubmitSchoolData}
                        >Сохрранить изменения </Button>
                        </AccordionDetails>
                        </Accordion>}
                        {contextValue.select_trener!==null&&<Accordion sx={{my:1}}>
                        <AccordionSummary
                        expandIcon={<AddOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Typography>Редактирование личных данных</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_family} label="Фамилия" variant="outlined" name="family" onChange={e=>setTrener({...trener, trener_family:e.target.value})}/>
                        
                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_name} label="Имя" variant="outlined" name="name" onChange={e=>setTrener({...trener, trener_name:e.target.value})}/>

                        <TextField fullWidth sx={{my:1}} id="outlined-basic" value={trener.trener_otchestvo} label="Отчество" variant="outlined" name="otchestvo" onChange={e=>setTrener({...trener, trener_otchestvo:e.target.value})}/>
                        <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            > 
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker fullWidth sx={{my:1}} variant="outlined" value={dayjs(trener.trener_age)} label="Дата рождения" onChange={(x, event) => setTrener({...trener, trener_age:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                        </LocalizationProvider> 
                        
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.trener)} onChange={(event,x)=>setTrener({...trener,trener:Number(x)})}/>} label="Тренер"
                        /> 
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.sud)} onChange={(event,x)=>setTrener({...trener,sud:Number(x)})}/>} label="Может судить"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={Boolean(trener.secret)} onChange={(event,x)=>setTrener({...trener,secret:Number(x)})}/>} label="Секретарь"
                        />  
                        </Box>                      
                        <Button     
                            fullWidth sx={{my:1}}           
                            variant="contained"
                            key="editTrener"
                            onClick={handleSubmit}
                        >Сохранить </Button>
                        <Button       
                            fullWidth sx={{my:1}}         
                            variant="contained"
                            key="Cancel"  
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>                       
                        </AccordionDetails>
                        </Accordion>}
                        </Box>
                        
              

            </form>
           
            </Grid>
            {Boolean(trener.sud) && <Grid item xs={12} md={6}>
            <Grid container spacing={2}  direction={{xs:"column",md:"row"}}>
            <Grid item xs={12} md={12}>
            <Box 
                            sx={{
                                padding:"10px",
                                display: 'grid',                    
                                gridAutoColumns:'1fr' ,
                                gap: 2,
                                gridTemplateColumns:'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center',overflow: "auto"              
                            }}
                            > 

<Accordion sx={{my:1}}>
                        <AccordionSummary
                        expandIcon={<AddOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <Typography>Добавить судейскую категорию</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                    <Box
                            sx={{
                                
                                padding:"10px",
                                my:1,
                                display: 'grid',                    
                                gridAutoColumns: {xs:'1fr',md:'2fr'} ,
                                gap: 2,
                                gridTemplateColumns: {xs:'repeat(1, 1fr)',md:'repeat(2, 2fr)'}  ,
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            > 
                                            
                        <Autocomplete
                            disablePortal
                                key={sud_categ_types.sud_categ_id}                                
                                value={selected_sud_categ}
                                id="sud_categ_types"
                                name='sud_categ_types'
                                autoHighlight={true}
                                options={sud_categ_types}
                                getOptionLabel={(option) =>`${option?.sud_categ_name}`}
                                onChange={(event, value) => {
                                    setSelectSudCateg(value);
                                    setSudCateg({...sud_categ, sud_categ_type_id:value?.sud_categ_id})
                                       
                                }}
                                renderInput={(params) => <TextField {...params} label="Судейская категория"                        
                                />}
                            />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker  variant="outlined"  value={selectedDate} label="Дата присвоения" onChange={(x, event) =>{
                                setSelectedDate(x);
                                 setSudCateg({...sud_categ,sud_categ_date_start:`${x.$y}-${x.$M+1}-${x.$D}`})}}/>
                        </LocalizationProvider>
                    <TextField fullWidth id="outlined-basic"  label="Приказ" value={sud_categ.sud_categ_prikaz} variant="outlined" name="prikaz" onChange={e=>setSudCateg({...sud_categ, sud_categ_prikaz:e.target.value})}/>
                    
                    <TextField fullWidth  id="outlined-basic"  label="Организация" value={sud_categ.sud_categ_org} variant="outlined" name="org" onChange={e=>setSudCateg({...sud_categ, sud_categ_org:e.target.value})}/>
                    </Box>
                    

                    <Button  
                            sx={{my:1}}
                            fullWidth              
                            variant="contained"
                            key="editSportsmen"
                            onClick={AddSudCateg}
                        >Добавить судейскую категорию </Button>
                        <Button  
                            sx={{my:1}}
                            fullWidth              
                            variant="outlined"
                            key="editSportsmen"
                            onClick={()=>ClearSudCateg()}

                        >Очистить </Button>
                    </AccordionDetails>
                    </Accordion>
                    <TableContainer sx={{  height: "300px", overflow: "auto"}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>            
                            <TableCell align="center">Судейская категория</TableCell>
                            <TableCell align="center">Дата присовения</TableCell>
                            <TableCell align="center">Приказ</TableCell> 
                            <TableCell align="center">Удалить</TableCell>
                        </TableRow>
                        </TableHead>
                    <TableBody>  
                    {
            sud_categs?.map((r,i)=>(
              <TableRow
              key={r.sud_categ_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                    <TableCell wrap align="center">{i+1}</TableCell>
                    <TableCell wrap align="center">{r.sud_categ_name}</TableCell>
                    <TableCell wrap align="center">{r.sud_categ_date_start}</TableCell>
                    <TableCell wrap align="center">
                        {`${r.sud_categ_prikaz}, ${r.sud_categ_org}`}
                        </TableCell> 
                    <TableCell wrap align="center" size="medium">
                    <IconButton  aria-label="delete" 
                  onClick={()=>deleteSudCateg(r.sud_categ_id)} 
                  size="large"
                  sx={{minWidth:10}}>
                      <DeleteOutlineOutlinedIcon />
                  </IconButton>
                  </TableCell>
                    </TableRow>))}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    </Box>
                    </Grid>
                    </Grid>
            </Grid>  } 
            </Grid> 
        </Back> 
     );
}
 
export default TrenerPage;