import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import {Context} from "../context";
import { useContext,useEffect,useState } from "react";
import {useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';



import 'dayjs/locale/ru';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const Registration = (props) => {
    let can=true;
    const contextValue=useContext(Context)
    const navigate = useNavigate();
    
    const [openList, setOpenList] =useState([])

    function gen_password(len){
        var password = "";
        var symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
        for (var i = 0; i < len; i++){
            password += symbols.charAt(Math.floor(Math.random() * symbols.length));     
        }
        return password;
    }    

    const [options, setOptions] = useState([]);
    const [values,setValues] = useState ({
        school_name:'',
        school_inn:'',
        school_adress:'',
        family:'',
        name:'',
        otchestvo:'',
        age:'',
        email:'',
        password:'',
        passwordcheck:'',
        user_type:0
    })
    useEffect(()=>{console.log(values)},[values])
    useEffect(()=>{if(values.user_type===null)setValues({...values,user_type:0})},[values.user_type])

    const handleInputChange = async (event, value) => {
        setOpenList(false)
        if (value) {
          let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + `0bcf160322fba45f1c4c583a62aafa04057c0629`
            },
            body: JSON.stringify({query: value})
        }
          try {
            const response = await fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,options).then(x => x.json());
            if(response.suggestions.length>0) setOptions(response.suggestions.map(item=>item));
          } catch (error) {
            console.error(error);
          }
        } else {
          setOptions([]);
        }
        setOpenList(true)
      }

      const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    

    function checkInput()
      {
        if(values.user_type===2)
        {
            if(values.family==='')
            {
               contextValue.setMsg("Введите фамилию")
               can=false
            }
            if(values.name==='')
            {
               contextValue.setMsg("Введите имя")
               can=false
            } 
        }     
        if(values.user_type===0)
        {
            if(values.school_name==='')
           {
               can=false;
               contextValue.setMsg("Не введено называние организации") 
              
           }
        }
            if(values.school_adress==='')
            {
                can=false;
                contextValue.setMsg("Не введен адрес") 
               
            }
        
           if(values.email==='')
           {
               can=false;
               contextValue.setMsg("Не введен Email") 
           }
           else
           {
            if (!validateEmail(values.email))  {
                can=false;
                contextValue.setMsg("Email не корректен") 
              }
           }
       
       if(!can)
       {
        contextValue.setTypeMsg("error")
        contextValue.setOpenAlert(true)
        }
   }
   async function  handleSubmit(event)
    {
        can=true;
            checkInput();
            
            if(can)
            {
        values.password=gen_password(12)
        event.preventDefault();
        axios.post(values.user_type===0?"/api/users/registration/school":`/api/users/registration/trener/${1}`,values)
        .then(res=>{
        if(res.data.Status===values.user_type===0?"Пользователь добавлен":"Тренер добавлен")
        {
            console.log("Пользователь добавлен");
            
                axios.post("/api/users/login",values)
                .then(res=>{
                    console.log(res.data.Status); 
                    console.log(res.data.Error);
                    if(res.data.Status==="Авторизация успешна")
                    {
                        contextValue.setTypeMsg("success")
                        contextValue.setMsg("Регистрация выполнена")
                        contextValue.setOpenAlert(true)
                        axios.post("/")
                        .then(res=>{
                          if(res.data.Status === "Авторизация успешна")
                          {
                            contextValue.setAuth(true)
                            contextValue.setUserId(res.data.user_id) 
                            contextValue.setUserEmail(res.data.user_email) 
                            contextValue.setSchoolID(res.data.school_id) 
                            contextValue.setTrenerID(res.data.trener_id)
                            contextValue.setUserType(res.data.user_type_id)
                            contextValue.setSuperUser(res.data.super_user)
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg("Вход выполнен")
                            contextValue.setOpenAlert(true)
                            navigate('/sportsmens')
                            setTimeout(props.setActive(false), 5000); 
                            }
                        else
                        {
                          contextValue.setAuth(false)
                        }
                      })
                  }
                  else 
                  {
                            contextValue.setTypeMsg("error")
                            contextValue.setMsg(res.data.Error)
                            contextValue.setOpenAlert(true)
                  }
        })}})
              .catch(err =>alert(err));
                              
          }
          }
    
    return (        
                <form onSubmit={handleSubmit}>
                     <Box
                sx={{
                    minWidth:{ xs: '250px', md: '450px' },
                    display: 'grid',                    
                    gridAutoColumns: '1fr',
                    gap: 2,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    alignContent:'center',
                    alignItems:'center'               
                }}
                > 
                 <ToggleButtonGroup
                                color="primary"
                                value={values.user_type}
                                exclusive
                                onChange={(event, newAlignment) => {
                                    setValues({...values, user_type:newAlignment})}}
                                aria-label="Platform"
                                sx={{my:1,  
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gridTemplateColumns: 'repeat(2, 1fr)'            
                                }}
                                >
                                <ToggleButton value={0}>Организация</ToggleButton>
                                <ToggleButton value={2}>Независимый тренер</ToggleButton>
                        </ToggleButtonGroup>
                    
                {values.user_type===0&&
                <>
                <TextField
                    required
                    id="outlined-required"
                    label="Наименование организации"
                    onChange={e=>setValues({...values, school_name:e.target.value})}
                />
                <TextField
                    id="outlined-required"
                    label="ИНН"
                    onChange={e=>setValues({...values, school_inn:e.target.value})}
                />
                </>}
                {values.user_type===2&&
                <>
                 <TextField id="outlined-basic" label="Фамилия" variant="outlined" name="family" onChange={e=>setValues({...values, family:e.target.value})}/>
                        
                 <TextField id="outlined-basic" label="Имя" variant="outlined" name="name" onChange={e=>setValues({...values, name:e.target.value})}/>

                 <TextField id="outlined-basic" label="Отчество" variant="outlined" name="otchestvo" onChange={e=>setValues({...values, otchestvo:e.target.value})}/>
                 <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                     <DatePicker variant="outlined" label="Дата рождения" onChange={(x, event) => setValues({...values, age:`${x.$y}-${x.$M+1}-${x.$D}`})}/>
                 </LocalizationProvider>
                 </>
                }
                <Autocomplete
                            autoHighlight={true}
                            disableClearable
                            freeSolo
                            open={openList}
                            options={options}
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Адрес" variant="outlined" name="championat_adress"/>)}
                            getOptionLabel={(option) =>
                                {if(option?.data!==undefined)
                                   return `${option?.data?.country!==null?option?.data.country:""}${option?.data?.region_with_type!==null?`, ${option?.data.region_with_type}`:""}${option?.data?.city!==null?`, ${option?.data.city}`:""}${option?.data?.street_with_type!==null?`, ${option?.data?.street_with_type}`:""}${option?.data?.house_type!==null?`, ${option?.data?.house_type}`:""}${option?.data?.house!==null?`,${option?.data?.house}`:""}`
                                    else return ""}}
                            onChange={(event, value)=>
                                {
                                    setValues({...values,school_adress:JSON.stringify(value)})}
                                }
                            filterOptions={(options, { inputValue }) =>
                                options.length>0 && options?.filter((option) => {
                                const inputWords = inputValue.toLowerCase().replace(/[.,%]/g, '').split(" ");
                                const optionWords = JSON.stringify(option.data).toLowerCase().split(" ");
                                // console.log(inputWords)
                                return inputWords.every((word) =>
                                optionWords.some((optionWord) => optionWord.includes(word))
                                );
                            })
                            }
                            />
                
                <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    onChange={e=>setValues({...values, email:e.target.value.toLowerCase().trim()})}
                />
               
                <Button                
                            variant="contained"
                            key="login"
                            onClick={handleSubmit}
                        >Регистрация</Button>  
                     <Button                
                            variant="outlined"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена</Button>             
                </Box>
            </form>
            

    )
}
 
export default Registration;