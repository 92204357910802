import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useContext,useEffect,useState } from "react";
import axios from 'axios';
import {Context} from "../context";
import "./../styles/main.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import logo from "./../img/Logo.png"




const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
          '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }
    },
  });




const AllResult = (props) => {
    const classes = useStyles();
    const [order_member,setOrderMember] = useState([])

    const contextValue=useContext(Context)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: "@page { size: auto; margin: 0mm; }",
        contentStyle: { transform: 'scale(0.8)', transformOrigin: 'top left' }
      });

    useEffect(()=>
        {            
                axios.get(`/api/orders/order_member/all/${contextValue.championat_id}`)
                .then(res=>{
                    console.log(res.data)
                    setOrderMember(res.data) 
                  })
                .catch(err=>console.log(err)) 
        },[]);
        
    useEffect(()=>
    {
      console.log(order_member)
    },[order_member])

    useEffect(()=>
      {
        console.log(props.orders)
      },[props.orders])
   
    const componentRef = useRef();
    function resulrow(om,o,i){
      return (
        <>   
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>                  
                    <Typography fontSize={8}>{om.composition_type_name.split("\n")[i]}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.psbti}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.ti1}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.ti2}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.ti3}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.ti4}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{parseFloat(o.ertime+o.spec+o.warn).toFixed(2)}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                      <Typography fontSize={10}>{o.avgti}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                      <Typography fontSize={10}>{o.avgtix2}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                      <Typography fontSize={10}>{o.difficulty}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                      <Typography fontSize={10}>{o.scoredifficulty}</Typography>
                    </TableCell>
                    <TableCell align='center' sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.psbart}</Typography> 
                    </TableCell>
                    <TableCell align='center' sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.art1}</Typography> 
                    </TableCell>
                    <TableCell align='center' sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.art2}</Typography> 
                    </TableCell>
                    <TableCell align='center' sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.art3}</Typography> 
                    </TableCell>
                    <TableCell align='center' sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.art4}</Typography> 
                    </TableCell>
                    <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.scoreart}</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={1} sx={{ border: 1 }}>
                    <Typography fontSize={10}>{parseFloat(o.height+o.suit+o.zastup+o.sbavkitl+o.other).toFixed(2)}</Typography>
                    </TableCell>
                    <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{o.score}</Typography>
                    </TableCell>


        </>
      )
    }
    return (
    <>
    <Box alignItems={'center'} width={'100%'} sx={{p:3 }} ref={componentRef} pageStyle={{size:'landscape'}}>
  
    
    {Array.from(new Set(props.orders[0]?.map((obj)=>obj.header)))?.map((o,i)=>(
      <>
          <TableContainer sx={{ my:1}} >
        <Table style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
               <TableBody> 
                <TableRow>
                  <TableCell align='center' style={{width: 100, border:0}} rowSpan={2}>
                    <img width={100} src={logo}></img> 
                  </TableCell>
                  <TableCell colSpan={4} style={{width: '90%', border:0}} align='center'>
                      <Typography fontSize={24}><strong>{contextValue.championat.championat_name}</strong></Typography>
                  </TableCell>
                </TableRow>
                    <TableRow>
                    <TableCell style={{ border:0}} align='center'>
                      <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography>
                    </TableCell>
                    <TableCell style={{width: '50%',border:0}} align='center'>
                      {/* <Typography fontSize={10}>{contextValue.championat.championat_start_date}-{contextValue.championat.championat_end_date}</Typography> */}
                    </TableCell>
                    <TableCell  align='center' style={{ border:0}}>
                      <Typography fontSize={10}> {`${JSON.parse(contextValue.championat.championat_adress)?.data?.country!==null?JSON.parse(contextValue.championat.championat_adress)?.data.country:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.city!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.city}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house!==null?`,${JSON.parse(contextValue.championat.championat_adress)?.data?.house}`:""}`}</Typography>
                    </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
                </TableContainer>  
      <TableContainer sx={{ my:1}} >
        <Table style={{ width: "100%", tableLayout: "auto" }}  aria-label="sticky table" size='small'  sx={{
                  '& .MuiTableCell-sizeSmall': {
                    padding: '1px',
                  },
                }}
              > 
              
               <TableBody> 
               <TableRow>
                <TableCell align='center' colSpan={"100%"}>
                <Typography width={'100%'} sx={{my:1, fontStyle: 'italic'}} fontSize={14}> 
                      {o}
                  </Typography>
                </TableCell>
            
            </TableRow>
                <TableRow>
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                  <Typography fontSize={10}> 
                      М</Typography>
                  </TableCell>
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}> 
                    Регион
                    Ведомство</Typography>
                </TableCell>
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Фамилия,имя</Typography>
                </TableCell>
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Дата рождения</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="КПН"&&
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Уровень подготовки</Typography>
                </TableCell>}
                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                <TableCell align="center"rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Разряд имеет</Typography>
                </TableCell>}
                {/* {contextValue.championat.championat_style_name==="АКД"&&
                <TableCell align="center"rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Субъект РФ</Typography>
                </TableCell>} */}
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>Тренеры</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="АКД"&&
                 
                 <>
                 <TableCell align="center" colSpan={11}  sx={{ border: 1 }}>
                 <Typography fontSize={8}>I Упражнение</Typography> 
               </TableCell>
               <TableCell align="center" colSpan={11}  sx={{ border: 1 }}>
               <Typography fontSize={8}>II Упражнение</Typography> 
             </TableCell>
               <TableCell align="center" colSpan={1} rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={8}>Оконч. оценка</Typography> 
               </TableCell>
               <TableCell align="center" colSpan={1} rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={8}>ЕВСК</Typography> 
               </TableCell>
               </>}
               {contextValue.championat.championat_style_name==="Батут"&&
                <>
                <TableCell align="center" colSpan={1}  sx={{ border: 1, padding:0, margin:0}}>
                <Typography fontSize={8}>Э1</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э2</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Э3</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э4</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э5</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э6</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э7</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э8</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Э9</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Э10</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> П</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Тех.</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}>Тр.</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Пр.</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Вр.</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Оценка</Typography> 
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
              <Typography fontSize={8}> Итого</Typography> 
              </TableCell>
                </>
                }
             
                {contextValue.championat.championat_style_name==="КПН"&&
                 <>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Отжимания</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Прыжок в длину</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={6}>Подтягивание в висе на перекладине</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Пресс</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Наклон с тумбы</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                 <Typography fontSize={6}>Гиперэкстензия</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Комбинация</Typography> 
                 </TableCell>
                 <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                 <Typography fontSize={7}>Сумма баллов</Typography> 
                 </TableCell>
                 </>
                }
                 {contextValue.championat.championat_style_name==="Парно-групповое"&&
                 <>
                  <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                      <Typography fontSize={8}>Упражнение</Typography>
                  </TableCell>
                  <TableCell align="center" colSpan={5}  sx={{ border: 1 }}>
                    <Typography fontSize={10}>Техника исполнения</Typography>
                  </TableCell>
                  <TableCell align="center"  rowSpan={2} sx={{ border: 1 }}>
                  <Typography fontSize={10}>Сбавки СТ</Typography>
                  </TableCell>
               <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
               <Typography fontSize={10}>ОЦЕНКА ТИ</Typography>
               </TableCell>
              <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
               <Typography fontSize={10}>ОЦЕНКА ТИ*2</Typography>
               </TableCell>
               <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={10}>Трудность</Typography>
                    </TableCell>
                    <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                    <Typography fontSize={10}>Оценка Т</Typography>
                    </TableCell>
               <TableCell colSpan={5} align="center" sx={{ border: 1 }}>
                <Typography fontSize={10}>Артистизм</Typography>
                </TableCell>
                <TableCell rowSpan={2} align="center"   sx={{ border: 1 }}>
                <Typography fontSize={10}>Оценка А</Typography>
                </TableCell> 
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={10}>Сбавки ПСБ</Typography>
                </TableCell>
                <TableCell align="center" rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={10}>Общая оценка</Typography>
                </TableCell>
                <TableCell align="center"  width={'80px'} rowSpan={2} sx={{ border: 1 }}>
                    <Typography fontSize={8}>Вып. разряда</Typography>
                </TableCell>
                 </>
                }
                </TableRow>
                {contextValue.championat.championat_style_name==="АКД"&&
                  <TableRow>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т1</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т2</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т3</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т4</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т5</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т6</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>E</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>D</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>штраф</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>бонус</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>сумма</Typography> 
                  </TableCell>
                   <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т1</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т2</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т3</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т4</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т5</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>т6</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>E</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>D</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>штраф</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>бонус</Typography> 
                  </TableCell>
                  <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                  <Typography fontSize={8}>сумма</Typography> 
                  </TableCell>
              
                   </TableRow>
                }
               
                {contextValue.championat.championat_style_name==="Парно-групповое"?
                <TableRow>
                <TableCell align="center"  sx={{ border: 1 }}>
               <Typography fontSize={10}>ПСБ</Typography>
               </TableCell>
               <TableCell align="center"  sx={{ border: 1 }}>
               <Typography fontSize={10}>ТИ-1</Typography>
               </TableCell>
               <TableCell align="center"  sx={{ border: 1 }}>
               <Typography fontSize={10}>ТИ-2</Typography>
               </TableCell>
               <TableCell align="center"  sx={{ border: 1 }}>
               <Typography fontSize={10}>ТИ-3</Typography>
               </TableCell>
               <TableCell align="center"  sx={{ border: 1 }}>
               <Typography fontSize={10}>ТИ-4</Typography>
               </TableCell>
               <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>ПСБ</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-1</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-2</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-3</Typography>
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                <Typography fontSize={10}>А-4</Typography>
                </TableCell>
               </TableRow>
               :
               <TableRow></TableRow>
               }
                {order_member.length>0&&
                  props.orders[0]?.filter((x)=>x.header===o)?.map((om,i)=>(
                <>
                <TableRow>
                <TableCell align="center" rowSpan={contextValue.championat.championat_style_name==="Парно-групповое"||contextValue.championat.championat_style_name==="Батут"?JSON.parse(om.score_json).length+1:1} sx={{ border: 1 }}>
                <Typography fontSize={8}>{om.place}</Typography>
                </TableCell>
                <TableCell align="center" rowSpan={contextValue.championat.championat_style_name==="Парно-групповое"||contextValue.championat.championat_style_name==="Батут"?JSON.parse(om.score_json).length+1:1} sx={{ border: 1 }}>
                <Typography fontSize={7}>{order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.school_name}</Typography>
                </TableCell>
                <TableCell align="left" rowSpan={contextValue.championat.championat_style_name==="Парно-групповое"||contextValue.championat.championat_style_name==="Батут"?JSON.parse(om.score_json).length+1:1} sx={{ border: 1,whiteSpace: "pre"}}>
                <Typography fontSize={8}>{contextValue.championat.championat_style_name==="Парно-групповое"?order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)].FN:order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.FN_KPN}</Typography>
                </TableCell>
                <TableCell align="center" rowSpan={contextValue.championat.championat_style_name==="Парно-групповое"||contextValue.championat.championat_style_name==="Батут"?JSON.parse(om.score_json).length+1:1}  sx={{ border: 1,whiteSpace: "pre" }}>
                <Typography fontSize={8}>{contextValue.championat.championat_style_name==="Парно-групповое"?order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)].age:order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.AGE_KPN}</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                <TableCell align="center" rowSpan={JSON.parse(om.score_json).length+1} sx={{ border: 1,whiteSpace: "pre" }}>
                  <Typography fontSize={8}>{order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.razryad_name}</Typography>
                </TableCell>
                }
                {contextValue.championat.championat_style_name==="КПН"&&
                <TableCell align="center" sx={{ border: 1 }}>
                <Typography fontSize={8}>{order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.kpn}</Typography>
                </TableCell>
                }

                <TableCell align="center" rowSpan={contextValue.championat.championat_style_name==="Парно-групповое"||contextValue.championat.championat_style_name==="Батут"?JSON.parse(om.score_json).length+1:1}  sx={{ border: 1,whiteSpace: "pre" }}>
                <Typography fontSize={8}>{order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.treners}</Typography>
                </TableCell>
                {contextValue.championat.championat_style_name==="КПН"&&
                 <>
                 <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn1}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn3}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn4}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn5}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn6}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].kpn7}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].score}</Typography> 
                </TableCell>
                </>
                }
                {contextValue.championat.championat_style_name==="АКД"&&
                 <>
                 <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t1}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t3}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t4}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t5}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t6}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].d}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].shtraf}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].bonus}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].summa}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t1_2}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t2_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t3_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t4_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t5_2}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].t6_2}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].d_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].shtraf_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].bonus_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].summa_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].score}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].evsk}</Typography> 
                </TableCell>
                </>
                }
                {contextValue.championat.championat_style_name==="Батут"&&
                 <>
                 <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e1}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e3}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e4}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e5}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e6}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e7}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e8}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e9}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e10}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].p}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].tex}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].tr}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].pr}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].vr}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].s}</Typography> 
                </TableCell>
                <TableCell align='center' rowSpan={2} sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].score}</Typography> 
                </TableCell>
                </>
                }
                
                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                 <>
                    {om.score_json!==undefined&&JSON.parse(om.score_json).map((o,i)=>(
                    <>
                    {i===0 &&<>
                      {resulrow(om,o,i)}
                      <TableCell align="center" rowSpan={JSON.parse(om.score_json).length} sx={{ border: 1 }}>
                        <Typography fontSize={8}>{order_member[order_member.findIndex(x=>x.order_member_id===om.order_member_id)]?.razryad_short_name}</Typography>
                      </TableCell>
                    </>                    
                    }
                    </>
                  ))}
                 </>
                 
                }
                
                </TableRow>
                {contextValue.championat.championat_style_name==="Батут"&&
                <TableRow>
                   <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e1_2}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e2_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e3_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e4_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e5_2}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e6_2}</Typography> 
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e7_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e8_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e9_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].e10_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].p_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].tex_2}</Typography> 
                </TableCell>
                <TableCell align='center'   sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].tr_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].pr_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].vr_2}</Typography> 
                </TableCell>
                <TableCell align='center' sx={{ border: 1 }}>
                <Typography fontSize={8}>{props.orders[0].length>0&&JSON.parse(om?.score_json)[0].s_2}</Typography> 
                </TableCell>
                </TableRow>
                }

                {contextValue.championat.championat_style_name==="Парно-групповое"&&
                 <>
                    {om.score_json!==undefined&&JSON.parse(om.score_json).map((o,i)=>(
                    <>
                    {i!==0 &&
                        <TableRow>
                            {resulrow(om,o,i)}
                        </TableRow>
                    }
                </>
                ))}
                <TableRow>
                <TableCell align='center' colSpan={7} sx={{ border: 1 }}>
                    <Typography fontSize={10}>Сумма оценки за ТИ для выполнения норматива</Typography>
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(om.score_json).reduce((acc, val)=>acc+val.avgti,0)}</Typography>
                </TableCell>
                <TableCell align='center' colSpan={10} sx={{ border: 1 }}>
                    <Typography fontSize={10}>ИТОГОВАЯ СУММА БАЛЛОВ для определения места</Typography>
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(om.score_json).reduce((acc, val)=>acc+parseFloat(val.score),0).toFixed(2)}</Typography>
                </TableCell>
                <TableCell align='center'  sx={{ border: 1 }}>
                    <Typography fontSize={10}>{JSON.parse(om.score_json).reduce((acc, val)=>acc+val.avgti,0)}</Typography>
                </TableCell>
                </TableRow>
                </>
                }
                </>
                ))}

                     </TableBody>                    
                     </Table>
                     </TableContainer>
                     
                     {props.orders[1]!==null&&<TableContainer sx={{ my:1, breakAfter:'page'}} >
                  <Table style={{ width: "100%"}}  aria-label="sticky table" size='small'  sx={{
                            '& .MuiTableCell-sizeSmall': {
                              padding: '1px',
                            },
                          }}
                        > 
                         <TableBody> 
                         {props.orders[1]?.length>0 &&<>
                         <TableRow>
                              <TableCell style={{width:'20%', border:0}} sx={{whiteSpace: "pre"}} align='center'>
                                  {/* <Typography fontSize={10}> Должность, судейская категория </Typography> */}
                              </TableCell>
                              <TableCell style={{width:'30%', border:0}} rowSpan={10} align='center' alignItems='center' alignContent={'center'}>
                                  <Typography fontSize={18}> МП </Typography>
                              </TableCell>
                              <TableCell style={{width:'30%', border:0}} align='center'>
                                  {/* <Typography fontSize={10}> Подпись </Typography> */}
                              </TableCell>
                              <TableCell style={{width:'20%', border:0}} align='center'>
                                  {/* <Typography fontSize={10}> ФИО, регион, ведомство </Typography> */}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ border:0}} align='right'sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}>{`${props.orders[1][0]?.sud_role_name}\n${props.orders[1][0]?.sud_categ_name}`}</Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='left'>
                                  <Typography fontSize={12}>  </Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='right' sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}> {props.orders[1][0]?.FIO }</Typography>
                              </TableCell>
                              </TableRow>
                              </>
                            }
                            {props.orders[1]?.length>1 &&
                            <TableRow>
                              <TableCell style={{ border:0}} align='right' sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}>{`${props.orders[1][1]?.sud_role_name}\n${props.orders[1][1]?.sud_categ_name===null?"Без судейской категории":props.orders[1][1]?.sud_categ_name}`}</Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='left'>
                                  <Typography fontSize={12}>  </Typography>
                              </TableCell>
                              <TableCell style={{ border:0}} align='right' sx={{whiteSpace: "pre"}}>
                                  <Typography fontSize={12}> {props.orders[1][1]?.FIO} </Typography>
                              </TableCell>
                              </TableRow>}
                          </TableBody>                    
                     </Table>
                     </TableContainer>}
                     </>
                     ))}
                </Box>
                <Button onClick={handlePrint}>
                Печать
                </Button>
                </>
      );
}
 
export default AllResult;